<template >
    <div>
        <Property/>
    </div>
</template>
<script>
export default {
    components:{
        Property: () => import('@/components/transfer/Property')
    }
}
</script>
<style lang="">
    
</style>